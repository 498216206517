const getParameters = () => {
  const queryString = window.location.search.substring(1);
  const queries = queryString.split('&');
  let parameters = {};

  queries.forEach((query) => {
    const queryKey = query.split('=')[0];
    const queryValue = query.split('=')[1];

    parameters[queryKey] = decodeURIComponent(queryValue);
  });

  return parameters;
};

const haveQueryString = () => {
  if(window.location.search.substring(1) === '') {
    return false;
  }

  return true;
}

const klickartPublicAutofill = () => {
  if (!haveQueryString()) {
    return;
  }

  const autofillOptionsAttribute = 'data-klickart-form-autofill-options';
  const autofillFields = document.querySelectorAll(`[${autofillOptionsAttribute}]`);
  const parameters = getParameters();

  autofillFields.forEach((field) => {
    const options = JSON.parse(field.getAttribute(autofillOptionsAttribute));

    if(!options.enabled) {
      return;
    }

    if(parameters[options.parameter] === undefined) {
      return;
    }

    field.value = parameters[options.parameter];
  });
};

export { klickartPublicAutofill };
